/* * {
    border: 0.1rem solid black;
} */
$lightColor: #d3ff8c;
$darkColor: #233800;
$baseColor: #5e9900;
$secondaryColor: #9dff00;

$lightColor: #f1f4ff;
$darkColor: #000f42;
$baseColor: #07227a;
$secondaryColor: #81898d;

$lightColor: rgb(211, 164, 164);
$darkColor: rgb(44, 0, 0);
$baseColor: #83002c;
$secondaryColor: #9c5e6c;

$lightColor: rgb(228, 213, 213);
$darkColor: rgb(7, 7, 7);
$baseColor: #696969;
$secondaryColor: #ffa1b9;

$titleFont: "Questrial", sans-serif;
$textFont: "Raleway", sans-serif;

// Github Octocat =>

.github-corner svg {
    fill: $darkColor;
    color: $lightColor;
    position: absolute;
    top: 0;
    border: 0;
    right: 0;
}

.github-corner:hover .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
}
@keyframes octocat-wave {
    0%,
    100% {
        transform: rotate(0);
    }
    20%,
    60% {
        transform: rotate(-25deg);
    }
    40%,
    80% {
        transform: rotate(10deg);
    }
}
// <= Github Octocat

.print-corner {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    border-width: 0 0 8rem 8rem;
    border-color: transparent transparent transparent $darkColor;
    font-size: 2rem;
    svg {
        fill: $darkColor;
        color: $lightColor;
        position: relative;
        top: 1rem;
        right: 6.5rem;
    }
    &:hover:after {
        content: "Print me !";
        background-color: $lightColor;
        padding: 0.5rem;
        width: 10rem;
        height: 3rem;
        position: absolute;
        top: 2rem;
        left: -2rem;
        border-radius: 0.5rem;
    }
}

.qr-code {
    display: none;
}

.app {
    text-align: center;
}

.app-background {
    //background-image: url("./img/backgroundlg.jpg");
    background-image: linear-gradient(to bottom, $baseColor, $secondaryColor);
    //background-blend-mode: soft-light;
    background-color: $baseColor;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 120vh;
    //font-size: calc(10px + 2vmin);
    color: $baseColor;
    z-index: -5;
}

.flex-row {
    display: flex;
    justify-content: center;
}

.align-center {
    align-items: center;
}

.nav {
    padding: 2vh 2vw;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 0 auto 1rem auto;
}

.profilePic {
    width: 15vw;
    border-radius: 20%;
}

.nav .title {
    text-align: center;
    font-family: $titleFont; //"Limelight", cursive;
    color: $baseColor;
    font-size: 4rem;
    text-shadow: 0.1rem 0 0 $lightColor, -0.1rem 0 0 $lightColor,
        0 0.1rem 0 $lightColor, 0 -0.1rem 0 $lightColor,
        0.05rem 0.05rem 0 $lightColor, -0.05rem -0.05rem 0 $lightColor,
        -0.05rem 0.05rem 0 $lightColor, 0.05rem -0.05rem 0 $lightColor;
    a,
    a:link,
    a:hover,
    a:visited,
    a:active {
        color: $baseColor;
        text-decoration: none;
    }
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    margin-top: 5rem;
    div {
        font-size: 1.8rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-right: 0.2rem solid $baseColor;
        a,
        a:link,
        a:hover,
        a:visited,
        a:active {
            color: $baseColor;
            text-decoration: none;
        }
        &:hover {
            a {
                color: $baseColor;
            }
            transform: translate(1.2);
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    div:last-of-type {
        border-right: none;
    }
}

.content {
    width: 80vw;
    margin: auto;
    position: relative;
    color: $baseColor;
    font-size: 1.8rem;
}

#skills .flex-row:not(:first-child) {
    & > div {
        min-width: 33%;
        padding: 1rem;
    }
    & > div:not(:last-of-type) {
        border-right: 0.2rem solid rgba($secondaryColor, 0.2);
    }
    .title {
        color: $baseColor;
    }
    a {
        text-decoration: none;
        padding: 0.5rem;
        color: $lightColor;
        background-color: $baseColor;
        margin: 0.2rem;
        border-radius: 10px;
        padding: 5px 10px;
    }
}

.other-skills {
    margin-bottom: 2rem;
}

.post-it {
    font-size: 1.8rem;
}

.items {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

h5 {
    text-align: center;
    width: 90%;
    margin: auto;
    font-size: 1.8rem;
    span + span:before {
        content: " - ";
    }
}

.year-bubble {
    background-color: rgb(255, 255, 255);
    border: 0.1rem solid $baseColor;
    border-radius: 10%;
    margin: 1rem;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    position: relative;
}

.exp:not(:last-of-type) .year-bubble::after {
    content: " ";
    position: absolute;
    width: 4px;
    height: 100px;
    background-color: $baseColor;
    top: 100%;
}

.col-50 {
    width: 50%;
    padding: 1rem;
}

.none {
    display: none;
}

.social-media {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    width: 40%;
    span {
        padding: 1rem;
    }
    a,
    a:link,
    a:hover,
    a:visited,
    a:active {
        color: $baseColor;
        text-decoration: none;
        padding-left: 1rem;
    }
}

.details {
    display: none;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    p {
        padding: 0 20px;
    }
    a {
        display: block;
        padding: 0 20px 20px;
    }
}

.exp {
    display: flex;
    align-items: center;
    height: 100px;
    font-size: 1.8rem;
    background-color: rgba(255, 255, 255, 0.7);
    margin-bottom: 10px;
}

.exp:hover {
    .details {
        display: block;
        border: 0.5rem solid $baseColor;
    }
}

.bold {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

.hide {
    height: 20px;
    *:not(.year-bubble) {
        display: none;
    }
    .year-bubble {
        font-size: 0;
        min-width: 15px;
        min-height: 15px;
        margin: 2.8rem;
        background-color: $baseColor;
    }
}

.align-end {
    justify-content: flex-end;
    text-align: right;
    .year-bubble {
        order: 2;
    }
}

.identity {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    width: 90%;
    margin: 4rem auto;
    font-size: 1.5rem;
    background-color: $lightColor;
    div {
        width: 45%;
        p {
            svg {
                position: relative;
                left: 0.5rem;
            }
            span {
                padding-left: 2rem;
            }
            span:not(:first-of-type) {
                padding-left: 3.3rem;
            }
        }
    }
}

.cont-info {
    display: none;
}

.project {
    padding: 20px;
    margin-top: 1rem;
    background-color: rgba(255, 255, 255, 0.5);
}

h2 {
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    text-align: center;
    padding: 20px;
    margin: 0;
}

.wrapper {
    margin-top: 1rem;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 2rem;
}

.github {
    &:link,
    &:active,
    &:hover,
    &:visited {
        text-decoration: none;
        color: white;
        background-color: #222;
        padding: 1rem;
        border-radius: 5px;
    }
}

h3 {
    text-align: center;
}

.tags {
    display: flex;
    flex-flow: row wrap;
    a {
        text-decoration: none;
        padding: 0.5rem;
        color: $lightColor;
        background-color: $baseColor;
        margin: 0.2rem;
        border-radius: 10px;
        padding: 5px 10px;
    }
}

.element,
.blockquote {
    padding: 10px 0 5px;
    &:before {
        content: "• ";
    }
}

@media screen and (max-width: 768px) {
    .profilePic {
        width: 30vw;
    }
    .flex-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .col-50 {
        align-content: center;
        justify-content: center;
        width: 100%;
        padding: 1rem;
        a {
            img {
                margin: auto;
                display: block;
                width: 100%;
            }
        }
    }
    #skills .flex-row:not(:first-child) > div {
        width: 100%;
    }
    .identity {
        .profilePic {
            display: none;
            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .profilePic {
        width: 50vw;
    }
    .navbar {
        display: none;
    }
    .nav,
    .content {
        width: 100%;
    }
    .navbar {
        flex-direction: column;
    }
    .flex-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .col-50 {
        align-content: center;
        justify-content: center;

        width: 100%;
        padding: 1rem;
        a {
            img {
                margin: auto;
                display: block;
                width: 100%;
            }
        }
    }
    .identity {
        width: 90vw;
        position: relative;
        font-size: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        div {
            width: 100%;
        }
        p {
            width: 100%;
            span:not(:first-of-type) {
                padding-left: 3.3rem;
            }
        }
        .social-media {
            padding: 0;
            span {
                padding-left: 0.5rem;
            }
        }
        .profilePic {
            display: none;
            img {
                width: 100%;
            }
        }
    }
    .github-corner:hover .octo-arm {
        animation: none;
    }
    .github-corner .octo-arm {
        animation: octocat-wave 560ms ease-in-out;
    }
}

@media print and (min-width: 200px) and (max-width: 1200px) {
    .post-it {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        h4 {
            margin: 0.2rem;
        }
    }
    .github-corner,
    .print-corner {
        display: none;
    }
    .cont-info {
        display: flex;
        flex-direction: column;
        font-size: 1.3rem;
        p {
            margin: 0.5rem 0;
            display: flex;
            flex-flow: row wrap;
            span {
                padding-left: 1rem;
            }
            span:not(:first-of-type) {
                padding-left: 2.2rem;
            }
        }
    }
    #projects {
        display: none;
    }
    .navbar {
        display: none;
    }
    .nav {
        font-size: 0.8rem;
        width: 100%;
        padding: 0 0 1rem 0;
        border-bottom: 0.1rem solid $baseColor;
        .title {
            margin-top: 0;
            text-shadow: none;
            font-size: 2.4rem;
            text-align: right;
        }
        span {
            font-size: 1rem;
            display: block;
            text-align: right;
        }
        span:before {
            content: "";
        }
        .flex-row {
            width: 100%;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
        h5 {
            font-size: 1rem;
            margin: 0;
            text-align: right;
        }
        .title-block {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 45%;
        }
    }
    #skills {
        .flex-row {
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: center;
        }
        border-bottom: 0.1rem solid $baseColor;
    }
    #experiences .flex-row {
        flex-flow: row nowrap;
        .col-50 {
            margin: 0;
            padding: 0;
        }
    }
    #about .flex-row:not(:first-of-type) {
        flex-flow: row nowrap;
        align-items: start;
        font-size: 1.3rem;
        .col-50 {
            //width: 70%;
            padding-left: 0;
        }
        h4 {
            margin: 0.5rem 0 0;
        }
        .element,
        .blockquote {
            padding: 0.2rem;
            text-align: justify;
        }
    }
    .blockquote,
    .element {
        &:before {
            content: "";
        }
    }
    .identity {
        display: none;
    }
    .wrapper {
        padding: 0;
    }
    .content {
        width: 100%;
        padding: 0;
    }
    h2 {
        margin: 0;
        margin-top: 0.5rem;
        text-align: left;
        padding: 0;
        font-size: 1.2rem;
    }
    .exp {
        width: 100%;
        p {
            margin: 0;
            padding: 0 1rem;
            font-size: 1.3rem;
        }
        height: 6rem;
        margin: 0;
        padding: 0;
        border-bottom: 0.1rem solid grey;
        .bold {
            font-size: 1.2rem;
        }
    }
    .year-bubble {
        min-width: auto;
        min-height: auto;
        border: 0;
        background-color: transparent;
    }
    .year-bubble::after {
        display: none;
    }
    .hide {
        display: none;
    }
    .col-50 {
        align-content: center;
        justify-content: center;
        width: 100%;
        padding: 1rem;
        a {
            img {
                margin: auto;
                display: block;
                width: 50%;
            }
        }
    }
    body {
        width: auto !important;
        margin: auto !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    caption {
        page-break-after: avoid; /* pas de saut après ces éléments */
    }
    .qr-code {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20vw;
        z-index: 10;
    }
}
